import { ApiError } from '@mechhive/api';
import { useGoogleLogin } from '@react-oauth/google';
import { Form, useSubmit, data, redirect } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { zx } from 'zodix';
import { createEvoTreeApi } from '~/api/evotree.api.server';
import { Button } from '~/components/shadcn/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '~/components/shadcn/components/ui/card';
import { commitSession, getSession } from '~/session.server';
import type { Route } from './+types/_empty.login._index';
import { createSeoMeta } from '@mechhive/remix';


const formDataSchema = z.object( {
  accessToken: z.string()
} );

const querySchema = z.object( {
  redirectUrl: z.string().optional()
} )

export const loader = async ( { request } : Route.LoaderArgs ) => {
  const session = await getSession( request.headers.get( 'Cookie' ) );
  const sessionExpired = session.get( 'sessionExpired' );

  return data( {
    sessionExpired
  } , {
    headers: {
      'Set-Cookie': await commitSession( session )
    }
  } );
}

export const meta: Route.MetaFunction = () => {
  return createSeoMeta( {
    title: 'Sign In | Evo Tree',
    robots: 'noindex'
  } )
}

export const action = async ( { request }: Route.ActionArgs ) => {
  const formData = await zx.parseForm( request, formDataSchema );
  const query = await zx.parseQuery( request, querySchema );
  const evoTreeApi = createEvoTreeApi( request );

  try {
    const response = await evoTreeApi.web.auth.verify( formData.accessToken );
    const session = await getSession();
    
    session.set( 'authToken', response.data.token );
    session.set( 'user', response.data.user );
    session.set( 'roles', response.data.roles.map( r => r.slug ) );
    
    return redirect( query.redirectUrl ? query.redirectUrl : '/', {
      headers: {
        'Set-Cookie': await commitSession( session )
      }
    } )
  } catch ( ex : ApiError<any> | unknown ) {
    console.error( ex );

    return ( {
      success: false,
      status: ex instanceof ApiError ? ex.response.status : null
    } )
  }
}

const LoginPage = ( { loaderData, actionData }: Route.ComponentProps ) => {
  const submit = useSubmit();

  const formRef = useRef<HTMLFormElement>( null );
  
  const [ accessToken, setAccessToken ] = useState<string | undefined>( undefined );

  const googleLogin = useGoogleLogin( {
    flow: 'auth-code',
    select_account: true,
    ux_mode: 'popup', 
    onSuccess: ( response ) => {
      setAccessToken( response.code );
    }
  } );

  useEffect( () => {
    if ( accessToken != null ) {
      submit( formRef.current );
    }
  }, [ accessToken ] );

  return (
    <div className={ 'w-screen h-screen flex items-center justify-center flex-col gap-8' }>
      { actionData?.success === false &&
        <Card>
          <CardHeader>
            <CardTitle>Oops!</CardTitle>
          </CardHeader>
          <CardContent className={ 'max-w-sm text-sm' }>
            { 
              match( actionData?.status )
                .with( 401, () => '(HTTP 401) Incorrect credentials. Verify and retry. Still stuck? Contact support, we\'re here to help.' )
                .with( 403, () => '(HTTP 403) Access Denied! Verify your account status. For more assistance, contact support.' )
                .with( null, () => 'Your login failed due to an unexpected issue. Please retry. If the issue persists, let our support team assist you.' )
                .otherwise( () => `(HTTP ${actionData?.status}) Login failed due to an unexpected issue. Please retry. Contact support if this continues.` )
            }
          </CardContent>
        </Card>
      }
      { loaderData.sessionExpired === true &&
        <Card>
          <CardHeader>
            <CardTitle>Session Expired</CardTitle>
          </CardHeader>
          <CardContent className={ 'text-sm' }>
            Your session has expired, please re-log in to continue using Evo Tree.
          </CardContent>
        </Card>
      }
      <div className={ 'flex items-center justify-center flex-col gap-8' }>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="96"
          height="96"
          color="#000000"
          className="text-primary"
          fill="none">
          <path
            d="M12 22V9"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
          <path
            d="M9.00195 18.002C7.3451 18.002 6.00195 16.6234 6.00195 14.9665C4.30581 14.7239 3.00195 13.2652 3.00195 11.502C3.00195 10.0052 3.94147 8.7279 5.26293 8.22759C5.09522 7.85339 5.00195 7.43856 5.00195 7.00195C5.00195 5.3451 6.3451 4.00195 8.00195 4.00195C8.3922 4.00195 8.76505 4.07647 9.10703 4.21204C9.45374 2.93842 10.6185 2.00195 12.002 2.00195C13.3854 2.00195 14.5502 2.93842 14.8969 4.21204C15.2389 4.07647 15.6117 4.00195 16.002 4.00195C17.6588 4.00195 19.002 5.3451 19.002 7.00195C19.002 7.43856 18.9087 7.85339 18.741 8.22759C20.0624 8.7279 21.002 10.0052 21.002 11.502C21.002 13.2653 19.698 14.724 18.0017 14.9665C18.0017 16.6234 16.6588 18.002 15.002 18.002"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
          <path
            d="M12 15L14.5 12.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
          <path
            d="M12 13L9.5 10.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
          <path
            d="M10 22H14"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
        </svg>
        <Form
          ref={ formRef }
          method="POST">
          <input
            type="hidden"
            name="accessToken"
            value={ accessToken } />
          <Button 
            className={ 'flex flex-row gap-2.5 gsi-material-button' } 
            onClick={ googleLogin }  
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className={ 'w-4 h-4' }
              style={ { display: 'block' } }>
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path
                fill="none"
                d="M0 0h48v48H0z"></path>
            </svg>
            Sign in with Google
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default LoginPage;
